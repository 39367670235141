import { template as template_18e26b5c33064e9db5355254d96a67e9 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_18e26b5c33064e9db5355254d96a67e9(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
