import { template as template_0d83989fa8f34132b38a131333b48a6b } from "@ember/template-compiler";
const FKLabel = template_0d83989fa8f34132b38a131333b48a6b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
