import { template as template_a13882e8457d417db1bc42c36a6d0787 } from "@ember/template-compiler";
const WelcomeHeader = template_a13882e8457d417db1bc42c36a6d0787(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
