import { template as template_ead0789d555a4d598d0e3aed2fc5efef } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_ead0789d555a4d598d0e3aed2fc5efef(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
